import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Suggest from "@/views/Suggest.vue";
import report from "@/views/report.vue";
import excuse from "@/views/excuse.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'The Worst Crit of Your Life',
      metaTags: [
        {
          name: 'description',
          content: 'A website that asks you tough questions about your design project'
        },
        {
          property: 'og:description',
          content: 'A website that asks you tough questions about your design project,'
        }
      ]
    }
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/suggest",
    name: "Suggest a Question",
    component: Suggest,
  },
  {
    path: '/:slug',
    name: 'report',
    component: report,
    props: true
  },
  {
    path: '/excuse',
    name: 'Excuse of The Week',
    component: excuse,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
