<template>
    <div id="Contianer">
        <div class="questionArea">
            <div class="questionBox">
                <p class="question">{{ question }}</p>
            </div>
            <div class="imgBox">
                <div class="lottie-container" ref="lottie" v-if="!svg"></div>
            </div>
        </div>
        <div class="answerArea">
            <form class="answerBox" action="https://sheetdb.io/api/v1/p8urx8e2ptdzg" method="post" id="sheetdb-form" v-if="displayForm === true">
                <textarea v-model="suggestion" maxlength="140" placeholder="your suggestion..." name="data[question]" />

                <div class="submitbox" v-if="displaySumbit === true">
                    <!-- RESIZE BOX AUTO //TODO-->
                    <button type="submit" class="button" v-on:click="excuseButton">Submit Question</button>
                    <p class="wordCount">{{ suggestion.length }}/140</p>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import lottie from "lottie-web";
import lottieData from "../assets/datatest.json";
export default {
    // name: 'NewerWebsiteHome',

    // components: { ComponentName },

    // directives: { DirectiveName },
    computed: {
        Sortedposts: function() {
            return this.posts
                .slice()
                .filter((posts) => posts.Entry_No <= this.Week)
                .sort((p1, p2) => {
                    let modifier = 1;
                    if (this.sortDirection === "desc") modifier = -1;
                    if (p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier;
                    if (p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
                    return 0;
                });
        },
    },

    data() {
        return {
            animFrames: [],
            animFramesWeight: [3, 2, 2, 2, 1, 1, 1, 1, 1, 1],
            previousSeg: 99,
            anim: null,
            question: "Ah, so you have a question to suggest. Go ahead",
            suggestion: "",
            displaySumbit: true,
            personAsking: 1,
            personAskingLine: [],
            displayForm: true,
        };
    },
    async mounted() {
        var form = document.getElementById("sheetdb-form");
        form.addEventListener("submit", (e) => {
            e.preventDefault();
            fetch(form.action, {
                method: "POST",
                body: new FormData(document.getElementById("sheetdb-form")),
            })
                .then((response) => response.json())
                .then(() => {
                    // you can put any JS code here
                    this.question = "Hmm, we will consider it";
                    this.displayForm = false;
                });
        });
        this.anim = lottie.loadAnimation({
            renderer: "svg",
            container: this.$refs.lottie,
            loop: false,
            autoplay: false,
            animationData: lottieData,
            onComplete: "finshed",
        });
        this.createFrames();
        this.anim.addEventListener("complete", this.playPause);

        this.clearLines();
        this.playPause();
    },
    async created() {},

    methods: {
        excuseButton() {},

        playPause() {
            let seg = null;
            let randomSeg = this.randomInteger(0, this.animFrames.length);
            if (randomSeg === this.previousSeg) {
                randomSeg = this.randomInteger(0, this.animFrames.length);
            }
            this.previousSeg = randomSeg;
            seg = this.animFrames[randomSeg];
            console.log(seg);
            this.anim.playSegments(seg, true);
        },

        randomInteger: function(min, max) {
            return Math.floor(Math.random() * max + min);
        },

        clearLines() {
            if (this.svg === false) {
                this.personAskingLine = [document.getElementById("p1"), document.getElementById("p2"), document.getElementById("p3")];
                for (let n = 0; n < this.personAskingLine.length; n++) {
                    this.personAskingLine[n].style.display = "none";
                }
                this.personAskingLine[this.personAsking].style.display = "block";
            }
        },

        createFrames() {
            var framesSep = 60;
            var framesTot = this.anim.getDuration(this.anim);
            var animationNum = 0;
            for (let n = 0; n < framesTot; n += framesSep) {
                let times = this.animFramesWeight[animationNum];
                animationNum++;
                for (let m = 0; m < times; m++) {
                    // console.log("step:" + n, times);
                    this.animFrames.push([n, n + framesSep]);
                }
            }
            console.log(this.animFrames);
        },
        finshed() {
            console.log("lottie done");
        },
    },
};
</script>

<style lang="scss"></style>
