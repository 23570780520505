<template>
    <div id="Contianer">
        <div class="questionArea">
            <div class="questionBox">
                <p class="progress" v-if="num > -1 && num < 8">{{ num + 1 }} / 8</p>
                <p class="question">{{ question }}</p>
            </div>
            <div class="imgBox">
                <div class="lottie-container" ref="lottie" v-if="!svg"></div>
            </div>
        </div>
        <div class="answerArea">
            <div class="answerBox">
                <button
                    class="button"
                    v-on:click="answer(answerChooses[0])"
                    v-if="displaySumbit === false && nextQuestionShow === false && displayExitButton === false && consentOption === false"
                >
                    {{ answerChooses[0] }}
                </button>
                <button
                    class="button"
                    v-on:click="answer(answerChooses[1])"
                    v-if="displaySumbit === false && nextQuestionShow === false && displayExitButton === false && consentOption === false"
                >
                    {{ answerChooses[1] }}
                </button>
                <button
                    class="button"
                    v-on:click="answer(answerChooses[2])"
                    v-if="displaySumbit === false && nextQuestionShow === false && displayExitButton === false && consentOption === false"
                >
                    {{ answerChooses[2] }}
                </button>
                <button class="button" v-on:click="nextQuestion()" v-if="nextQuestionShow === true && displayExitButton === false && consentOption === false">
                    {{ nextQuestionText }}
                </button>
                <button class="button smaller" v-on:click="consentAns(true)" v-if="consentOption === true">
                    {{ copy.privacyResponse[0] }}
                </button>
                <button class="button smaller" v-on:click="consentAns(false)" v-if="consentOption === true">
                    {{ copy.privacyResponse[1] }}
                </button>
                <router-link
                    :to="{ name: 'report', params: { slug: this.hash, data: this.answersList, consent:this.consent } }"
                    class="button"
                    v-if="nextQuestionShow === false && displayExitButton === true && consentOption === false"
                    >Read Report</router-link
                >
                <textarea v-model="excuse" maxlength="140" placeholder="your excuse..." v-if="displaySumbit === true" />
                <div class="submitbox" v-if="displaySumbit === true">
                    <!-- RESIZE BOX AUTO //TODO-->
                    <button class="button" v-on:click="excuseButton">Submit Excuse</button>
                    <p class="wordCount">{{ excuse.length }}/140</p>
                </div>
            </div>
        </div>
        <div class="debugger" v-if="displayDebbuger === true">
            <div class="answersGhost" v-if="toggle === true">
                <li v-for="item in answersList.answers" :key="item.num" v-bind:class="[{ staticClass: true }, item.type]">Q{{ item.num }} - {{ item.text }}</li>
            </div>

            <button class="button" v-on:click="recordQuiz()">RecordThis</button>
            <button class="button" v-on:click="getPost()">Getpost</button>
            <button class="button" v-on:click="createQuestionArray()">Create Array</button>
            <button class="button" v-on:click="toggle = !toggle">answerlist</button>
            <button class="button" v-on:click="svg = !svg">svg on</button>
            <button class="button" v-on:click="playPause()">playpause</button>
        </div>
    </div>

    <!-- <div class="answerBox">
        <h1>Question Answer</h1>
        <li v-for="item in answersList.answers" :key="item.num" v-bind:class="[{ staticClass: true }, item.type]">
            {{ item.text }}
        </li>
    </div> -->
</template>

<script>
import PostService from "../PostService";
import questions from "../assets/questions.json";
import copy from "../assets/copy.json";
import lottie from "lottie-web";
import lottieData from "../assets/datatest.json";

import { TextareaAutogrowDirective } from "vue-textarea-autogrow-directive";
export default {
    /// lottie things

    computed: {
        sortedPosts: function () {
            return this.questions.filter((questions) => questions.Entry_No == this.Week);
        },
    },
    directives: {
        autogrow: TextareaAutogrowDirective,
    },
    data() {
        return {
            animFrames: [],
            animFramesWeight: [2, 2, 2, 2, 1, 1, 1, 1, 1, 1],
            previousSeg: 99,
            anim: null,
            questions: questions,
            copy: copy,
            error: "",
            question: "Loading",
            answersList: { slug: "", answers: [] },
            questionsAsked: [],
            num: -1,
            answerChooses: ["Yes", "Yes but I have an excuse", "No"],
            excuse: "",
            thingy: [],
            hash: "",
            displaySumbit: false,
            displayExitButton: false,
            displayDebbuger: false,
            personAsking: 0,
            personAskingLine: [],
            noOfQuestions: 8,
            questionArray: [],
            questionTypes: ["generic", "selfworth", "purpose", "health", "autonomy", "usability", "pleasure", "relationships"],
            nextQuestionShow: true,
            usedCopy: {
                questions: [],
                positive: [],
                negative: [],
                excuse_Pre: [],
                excuse_Post: [],
                excuse_Plus: [],
                intro: [],
                exit: [],
            },
            runs: 0,
            toggle: false,
            nextQuestionText: "I'm ready",
            svg: false,
            consent: false,
            consentOption: false,
        };
    },

    methods: {
        playPause() {
            let seg = null;
            let randomSeg = this.randomInteger(0, this.animFrames.length);
            if (randomSeg === this.previousSeg) {
                randomSeg = this.randomInteger(0, this.animFrames.length);
            }
            this.previousSeg = randomSeg;
            seg = this.animFrames[randomSeg];
            console.log(seg);
            this.anim.playSegments(seg, true);
        },

        randomInteger: function (min, max) {
            return Math.floor(Math.random() * max + min);
        },

        createQuestionArray: function () {
            for (let i = 0; i < this.noOfQuestions; i++) {
                this.getQuestionForArray(this.questionTypes[i]);
            }
            console.log(this.questionArray);
        },

        getQuestionForArray: function (type) {
            var randomNumber = this.randomInteger(0, this.questions.length);
            if (this.questions[randomNumber].do_not_show === "1") {
                // console.log("shouldn't be used");
            } else {
                // console.log("should be used");
            }
            if (
                this.usedCopy.questions.includes(randomNumber) === true ||
                this.questions[randomNumber][type] != 1 ||
                this.questions[randomNumber].do_not_show === "1"
            ) {
                if (this.usedCopy.questions.length === this.questions.length) {
                    this.question = "None Left";
                } else {
                    // console.log("not right");
                    this.getQuestionForArray(type);
                }
            } else {
                this.usedCopy.questions.push(randomNumber);
                this.questionArray.push(this.questions[randomNumber]);
            }
        },

        getQuestion: function () {
            if (this.personAsking === 2) {
                this.personAsking = 0;
            } else {
                this.personAsking++;
            }
            this.clearLines();
            var questionNum = this.questionArray[this.num];
            this.question = questionNum.question;
            this.answerChooses[0] = questionNum.yes_Answer;
            this.answerChooses[1] = questionNum.excuse_Answer;
            this.answerChooses[2] = questionNum.no_Answer;
            // if (questionNum.good_Answer === "YES") {
            //     this.answerChooses[1] = "No but I have an excuse";
            // } else {
            //     this.answerChooses[1] = "Yes but I have an excuse";
            // }
            this.logAnswer(this.num, "question", this.question);
            // console.log("questionNum: ", this.num, "QuestionsAsked: ", this.usedCopy.questions, "Answers so Far: ", this.answersList);
        },

        logAnswer(num, type, text) {
            // console.log("logAnser", num);
            if (num !== -1 && num !== 8) {
                this.answersList.answers.push({ num: num, type: type, text: text });
            } else {
                // console.log("ignored");
            }
        },

        answer: function (response) {
            // console.log("response: ", response);
            this.logAnswer(this.num, "answer", response);
            this.writeCopy(response);
            if (response == this.answerChooses[1]) {
                // console.log("excuse");
                this.displaySumbit = true;
            } else {
                this.nextQuestionShow = true;
            }
        },

        nextQuestion: function () {
            // console.log("Next Ques", this.num);
            this.num++;
            this.nextQuestionShow = false;
            if (this.num == 8) {
                // console.log("exit 8");
                this.consentFunc();
            } else if (this.num == 9) {
                console.log("exit 9");

                this.ending();
            } else {
                console.log("even after exit 9")
                if (this.num > 0) {
                    this.answersList.answers.push({ num: this.num, type: "answer", text: "-" });
                }
                this.getQuestion();
                this.nextQuestionText = "Next Question";
            }
        },

        consentFunc() {
            this.question = copy.privacy[0];
            this.consentOption =true;
        },

        consentAns(choice){
            if(choice){
                this.consent = true
            }else{
                this.consent = false
            }
            this.consentOption = false
            this.nextQuestion();
        },

        excuseButton: function () {
            // console.log(this.excuse);
            this.answersList.answers.push({ num: this.num, type: "excuse", text: this.excuse });
            this.displaySumbit = false;
            this.writeCopy("excuse_Post");
            this.excuse = "";
            this.nextQuestionShow = true;
        },

        generateRandomHash: function () {
            const characters = "0123456789abcdefghijklmnpqrstuvwxyz";
            let string = "";
            for (let i = 0; i <= 16; i++) {
                string += characters[Math.floor(Math.random() * characters.length)];
            }
            return string;
        },

        checkIfUsed: function (arrayName, number) {
            return this.usedCopy[arrayName].includes(number);
        },

        writeCopy: function (response) {
            // var copy = "Hmm";
            // console.log(response);
            // console.log(this.usedCopy.excuse_Pre.length);
            if (this.answerChooses.includes(response) === true) {
                var isPostive = this.questionArray[this.num].good_Answer;
                // console.log("isPositive: " + isPostive);
                if (response === this.answerChooses[1]) {
                    if (this.usedCopy.excuse_Pre.length < 4) {
                        this.getCopy("excuse_Pre");
                    } else {
                        this.getCopy("excuse_Plus");
                    }
                } else if (response === this.answerChooses[0] && isPostive === "YES") {
                    this.getCopy("positive");
                } else if (response === this.answerChooses[2] && isPostive === "NO") {
                    this.getCopy("positive");
                } else {
                    this.getCopy("negative");
                }
            } else if (response === "excuse_Post") {
                this.getCopy("excuse_Post");
            } else if (response === "intro") {
                this.getCopy("intro");
            } else if (response === "exit") {
                this.getCopy("exit");
                // console.log("gonna record");
                            

                if(this.consent){
                    this.hash = this.generateRandomHash();
                                this.answersList.slug = this.hash;

                this.recordQuiz();
                }else{
                    this.hash = "yourreport"
                    this.answersList.slug = this.hash;
                    this.$store.commit('change', this.answersList)

                    // console.log('no submit')
                    // console.log('store',this.$store.state.answers)
                }
            }
        },

        getCopy: function (type) {
            // console.log(type);
            var copy = "error";
            var randomNumber = this.randomInteger(0, this.copy[type].length);
            if (this.usedCopy[type].includes(randomNumber) === true) {
                // console.log("getCopy Allready done");
                // console.log("failed Random: ", randomNumber);
                if (this.copy[type].length === this.usedCopy[type].length) {
                    this.usedCopy[type].shift();
                    // console.log("shifted");
                    this.getCopy(type);
                } else {
                    this.getCopy(type);
                }
            } else {
                copy = this.copy[type][randomNumber];
                this.usedCopy[type].push(randomNumber);
                // console.table(this.usedCopy);
                this.question = copy;
                this.logAnswer(this.num, "question", copy);
                if (this.num > 6) {
                    this.nextQuestionText = "Finish Crit";
                }
            }
        },

        recordQuiz: async function () {
            // console.log("Recording Post");
            // console.log(this.hash);
            await PostService.insertPost(this.answersList);
        },

        getPost: async function () {
            // console.log("getPost");
            if (this.hash != "") {
                this.thingy = await PostService.getPost(this.hash);
                // console.log(this.thingy);
            } else {
                // console.log("no hash");
            }
        },

        startUp: function () {
            // console.log("starting");

            this.writeCopy("intro");
            this.personAskingLine[0].style.display = "none";
        },

        clearLines() {
            if (this.svg === false) {
                this.personAskingLine = [document.getElementById("p1"), document.getElementById("p2"), document.getElementById("p3")];
                for (let n = 0; n < this.personAskingLine.length; n++) {
                    this.personAskingLine[n].style.display = "none";
                }
                this.personAskingLine[this.personAsking].style.display = "block";
            }
        },

        ending() {
            this.writeCopy("exit");
            this.displayExitButton = true;
        },

        createFrames() {
            var framesSep = 60;
            var framesTot = this.anim.getDuration(this.anim);
            var animationNum = 0;
            for (let n = 0; n < framesTot; n += framesSep) {
                let times = this.animFramesWeight[animationNum];
                animationNum++;
                for (let m = 0; m < times; m++) {
                    // console.log("step:" + n, times);
                    this.animFrames.push([n, n + framesSep]);
                }
            }
            // console.log(this.animFrames);
        },
        finshed() {
            // console.log("lottie done");
        },
    },

    async created() {
        try {
            // console.log("before", this.usedCopy.questions);
            //             console.log('store', this.$store)

            // console.table(this.copy);
            this.createQuestionArray();
            this.startUp();
        } catch (err) {
            this.error = err.message;
        }
    },
    async mounted() {
        this.anim = lottie.loadAnimation({
            renderer: "svg",
            container: this.$refs.lottie,
            loop: false,
            autoplay: false,
            animationData: lottieData,
            onComplete: "finshed",
        });
        this.createFrames();
        this.anim.addEventListener("complete", this.playPause);

        window.addEventListener("keydown", (e) => {
            if (e.key == "Escape") {
                this.displayDebbuger = !this.displayDebbuger;
            }
        });
        this.clearLines();
        this.playPause();
    },
};
</script>

<style lang="scss">
#Contianer {
    height: calc(100vh - (3 *#{$padding}));
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .questionArea {
        // height: calc(100% - #{$padding * 4});
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .questionBox {
            max-width: 70vw;

            .question {
                font-size: 2.8em;
                text-align: center;
                // margin:  20px;
                margin-bottom: 0px;
                margin-top: 0px;
            }

            .progress {
                font-size: 1em;
                text-align: center;
                // margin:  20px;
                font-family: "PatrickHandSC";

                margin-bottom: 10px;
                margin-top: 0px;
            }
        }

        svg {
            width: 80vw !important;
            max-height: 50vh;
        }
    }

    .answerArea {
        // background-color: chocolate;
        height: 3 * $padding;

        //max-height: 150px;

        .answerBox {
            height: 100%;
            display: flex;
            min-width: 70vw;
            align-items: center;
            justify-content: center;

            .button {
                margin: 0px $padding;
                text-decoration: none;
                font-family: "PatrickHandSC";
                background: none;
                border: none;
                font-size: 1.8em;
                cursor: pointer;
                color: $black;
            }


            .button:hover {
                text-decoration: underline;
                transform: scale(1.25, 1.25);
                transform-origin: center center;
            }

            .submitbox {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .wordCount {
                font-size: 1.5em;
                margin: 0px;
                // margin-top:15px;
            }

            textarea {
                border: 2px solid $black;
                max-width: 50vw;
                width: 50vw;
                padding: 10px 20px;
                line-height: 1.5em;
                font-family: "PatrickHandSC";
                font-size: 1.5em;
                background: none;
                resize: none;
                text-align: center;
            }

            textarea:active {
                outline: 0px !important;
                -webkit-appearance: none;
                box-shadow: none !important;
            }
        }
    }
}
.debugger {
    position: absolute;
    bottom: 0;
    left: 0;

    .answersGhost {
        font-family: monospace;
        font-size: 0.8em;
        padding: 5px;
        position: absolute;
        bottom: 40px;
        min-width: 40px;
        max-width: 400px;
        min-height: 40px;
        background-color: rgba(0, 0, 255, 0.2);
        pointer-events: none;
        .question {
            font-weight: bold;
        }
    }
}
@media only screen and (max-width: 480px) {
    #Contianer {
        height: calc(100vh - (3 *#{$padding}));
        //background-color: blue;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .questionArea {
            // background-color: red;
            // height: calc(100% - #{$padding * 4});
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            .imgBox {
                width: 80vw;
            }

            .questionBox {
                max-width: 80vw;

                .question {
                    font-size: 2em;
                }
            }
        }

        .answerArea {
            // background-color: chocolate;
            height: 4 * $padding;

            //max-height: 150px;

            .answerBox {
                height: 100%;
                display: flex;
                min-width: 70vw;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .button {
                    margin: $padding/16 0px;
                    text-decoration: none;
                    font-family: "PatrickHandSC";
                    background: none;
                    border: none;
                    font-size: 1.5em;
                    cursor: pointer;
                    color: $black;
                }

                .button:hover {
                    text-decoration: underline;
                    transform: scale(1.25, 1.25);
                    transform-origin: center center;
                }

                .submitbox {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-top: $padding/4;
                }

                .wordCount {
                    font-size: 1.5em;
                    margin: 0px;
                    // margin-top:15px;
                }

                textarea {
                    border: 1px solid $black;
                    max-width: 80vw;
                    width: 80vw;
                    padding: 10px 15px;
                    line-height: 1em;
                    font-family: "PatrickHandSC";
                    font-size: 1.2em;
                    background: none;
                    resize: none;
                    text-align: center;
                }

                textarea:active {
                    outline: 0px !important;
                    -webkit-appearance: none;
                    box-shadow: none !important;
                }
            }
        }
    }
}
</style>
