<template>
    <div class="container">
        <div id="nav">
            <div class="left navy">
                <router-link class="navlink title" to="/">The Worst Crit of Your Life</router-link>
            </div>
            <div class="right navy">
                <div class="desktopNav">
                    <router-link class="navlink" to="/">Crit</router-link>
                    <router-link class="navlink" to="/excuse">Excuses</router-link>
                    <router-link class="navlink" to="/Suggest">Suggest</router-link>
                    <router-link class="navlink" to="/about">About</router-link>
                </div>
                <div class="mobileNav">
                    <p id="menuButton" v-on:click="openMenu()">{{ menuButtonText }}</p>
                </div>
                <!-- <a class="navlink" href="mailto:fred@mildlyupset.com" target="_blank" rel="noopener noreferrer">Contact</a> -->
            </div>
        </div>
        <div id="content">
            <router-view />
        </div>
        <!-- <div id="footer">
            <div class="left"></div>
            <div class="right"></div>
        </div> -->
        <div id="MobileMenu" v-if="menuButtonText === 'Close'">
            <router-link class="mobileNavLink" v-on:click="openMenu()" to="/">Crit</router-link>
            <router-link class="mobileNavLink" v-on:click="openMenu()" to="/excuse">Excuses</router-link>
            <router-link class="mobileNavLink" v-on:click="openMenu()" to="/Suggest">Suggest a question</router-link>
            <router-link class="mobileNavLink" v-on:click="openMenu()" to="/about">About</router-link>
        </div>
    </div>
</template>

<script>
// import PostComponent from './components/PostComponent.vue'

export default {
    name: "App",
    components: {},
    data() {
        return {
            menuButtonText: "Menu",
        };
    },
    created() {
        window.addEventListener("resize", () => {
            document.querySelector(":root").style.setProperty("--vh", window.innerHeight / 100 + "px");
        });
    },

    methods: {
        openMenu() {
            if (this.menuButtonText === "Menu") {
                this.menuButtonText = "Close";
            } else {
                this.menuButtonText = "Menu";
            }
        },
    },
};
</script>
<style src="@mr-scroll/core/dist/styles.css"></style>

<style lang="scss">
body,
html {
    font-family: "ReenieBeanie";
    color: $black;
    font-size: 16px;
    margin: 0px;
    padding: 0px;
}

body {
    background-color: $cream;
}

#nav {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: calc(100% - (2 * #{$padding}));
    padding: 0px $padding;
    max-height: $padding * 3;
}

.navy {
    display: flex;
    align-items: center;
    height: $padding * 3;
    z-index: 9999;
}

.navlink {
    //font-family: "PatrickHand";
    text-decoration: none;
    color: $black;
    margin-right: $padding/3;
    font-size: 1.5em;
    text-align: center;
}

.navlink:last-of-type {
    margin-right: 0px;
}

.right {
    .navlink.router-link-active {
        text-decoration: underline;
    }
}

.navlink.title.router-link-active {
    text-decoration: none;
}

.title {
    font-family: "ReenieBeanie";
    text-align: left;
    font-size: 2em;
}

.mobileNav {
    display: none;
}
#menuButton {
    text-decoration: none;
    color: $black;
    font-size: 1.5em;
}
#MobileMenu {
    display: none;
}
.desktopNav {
    display: flex;
    align-items: center;
    height: $padding * 3;
    z-index: 9999;
}
@supports (-webkit-touch-callout: none) {
}

@media only screen and (max-width: 480px) {
    #nav {
        width: calc(100% - (1 * #{$padding}));
        padding: 0px $padding/2;
    }
    .navy {
        height: $padding * 2;
    }

    .desktopNav {
        display: none;
    }
    .mobileNav {
        display: block;
    }
    #MobileMenu {
        display: flex;
        height: 100vh;
        width: 100vw;
        overflow: none;
        background-color: $cream;
        z-index: 999;
        position: absolute;
        top: 0;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .mobileNavLink {
        text-decoration: none;
        color: $black;
        margin: $padding/3;
        font-size: 2em;
    }
    .title {
        font-size: 1.5em;
    }
}
</style>
