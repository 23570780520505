import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---
import SmartTable from 'vuejs-smart-table'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import LottieAnimation from 'lottie-web-vue'
import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
  state () {
    return {
      answers: {}
    }
  },
  mutations: {
    change (state, object) {
      state.answers = object
    }
  }
})


createApp(App).use(store).use(router).use(SmartTable).use(PerfectScrollbar).use(LottieAnimation).mount('#app');

