<template>
     <div class="reportBox">
        <div class="answerBox">
            <h1>Excuse of The Week</h1>
            <p v-if="!excuses">loading...</p>
        <li v-for="excuse in sortedPosts" :key="excuse.week" >
            <p class="question excusePage">Week {{ excuse.week }}</p>
            <p class="answer excusePage">{{ excuse.excuse }}</p>
        </li>

        </div>
    </div>
</template>

<script>
// import excuses from "../assets/excuses.json";
import PostService from "../PostService";

export default {
    computed: {
        sortedPosts: function() {
            var sortlist = this.excuses;
            return sortlist.sort((a, b) => b.week - a.week);
        },
    },
    data() {
        return {
            excuses: [],
        };
    },
        async mounted() {
        console.log("getPost");
        if (this.hash != "") {
            let rawdata = await PostService.getPost('weekly');
            this.excuses = rawdata.data;
            console.log(this.excuses)
        } else {
            console.log("no luck");
        }
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
.question.excusePage{
    margin-bottom: 0px;
}

.answer.excusePage{
    margin-top: 0.5em;
    margin-bottom: 2em;
}
</style>
