<template>
    <div class="About">
        <div class="reportBox">
            <div class="answerBox">
                <h1 class="aboutH">About</h1>
                <p class="answer">
                    The Worst Crit Of Your Life is an experience designed to encourage reflection on how design influences happiness through an online roleplay
                    game that uses humour to enable designers and alike to test their concepts against a trio of virtual assessors.<br /><br />Since 2018,
                    London-based design studio Wolf in Motion has been exploring the theme of happiness in design, through research and experiments.<br /><br />
                    Their belief is that designers are too often encouraged to create products and experiences that focus on short term satisfaction of the
                    user, ignoring long term impacts on the society and the planet.<br /><br /><a
                        class="linkthingy"
                        target="_blank"
                        rel="noopener"
                        href="https://wolfinmotion.com/work/designing-for-happiness/"
                        >Click here</a
                    >
                    to read more about designing for happiness and get in touch.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            num: 0,
        };
    },
    methods: {
        show: function () {
            console.log("sort");
            if (this.num == 0) {
                document.getElementById("box").classList.remove("hidden");
                document.getElementById("But").innerText = "Hide the example";
                this.num = 1;
            } else {
                document.getElementById("box").classList.add("hidden");
                document.getElementById("But").innerText = "See an example";
                this.num = 0;
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.answer{
    font-family: "PatrickHand" !important;
}
.aboutH {
    margin-bottom: 0px;
}


.linkthingy {
    display: underline;
    color: $black;
}
</style>
