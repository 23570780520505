<template>
    <div class="reportBox">
        <div class="answerBox" v-if="!error">
            <h1>Your Report</h1>
            <p class="question intro">
                Here is the transcript of your crit on {{ date }}. <span v-if="hash !== 'yourreport'"> If you would like to share this with your boss or anyone else, send them this page.</span>
            </p>
            <p class="answer">- - -</p>
            <li v-for="item in answersList.answers" :key="item.num" v-bind:class="[{ staticClass: true }, item.type]">
                {{ item.text }}
            </li>
            <p class="answer">- - -</p>
            <p class="question intro" v-if="hash !== 'yourreport'">
                Share link: <a v-bind:href="url">{{ url }}</a>
            </p>
        </div>
                <div class="answerBox" v-if="error">
            <h1>{{errorMSG}}</h1>
                </div>
    </div>
</template>

<script>
import PostService from "../PostService";

export default {
    // name: 'NewerWebsiteHome',

    computed: {
        sortedPosts: function () {
            return this.posts.filter((posts) => posts.Slug == this.slug);
        },
    },
    // directives: { DirectiveName },

    data() {
        return {
            hash: this.$route.params.slug,
            consent: this.$route.params.consent,
            //  hash: 'hx134975amul41kh7',
            answersList: null,
            date: null,
            url: "",
            error: true,
            errorMSG: 'Loading...'
        };
    },
    async created() {
        console.log("route", window.location.href);
        this.url = window.location.href;
    },
    async mounted() {
        console.log("getPost");
        if (this.hash != "") {
            let current;
            if (this.hash == "yourreport") {
                console.log("this isnt a hash");
                current = new Date();
                console.log(new Date());
                this.answersList = this.$store.state.answers
            } else {
                this.thingy = await PostService.getPost(this.hash);
                this.answersList = this.thingy.data.results;
                this.date = this.thingy.data.createdAt;
                current = new Date(this.date);
            }

            this.date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
            console.log(this.date, this.answersList, current);
            if(this.answersList.answers){
                this.error = false;
            }else{
                this.error = true;
                this.errorMSG = 'Nothing here, sorry';
                console.log('finished')
            }
        } else {
            console.log("no hash");
        }
    },

    methods: {},
};
</script>

<style lang="scss" scope>
.reportBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2 * $padding;

    h1 {
        font-size: 2em;
    }

    .answerBox {
        text-decoration: none;
        font-size: 2em;
        text-align: center;
        list-style-type: none;
        word-wrap: break-word;
        width: 70vw;
        .question {
            margin-bottom: $padding/2;
        }

        .answer {
            font-size: 0.8em;
            margin-bottom: $padding/2;
            font-family: "PatrickHandSC";
        }

        .excuse {
            font-size: 0.8em;
            margin-bottom: $padding/2;
            font-family: "PatrickHandSC";
        }
    }
}
@media only screen and (max-width: 480px) {
    .reportBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1 * $padding;

        h1 {
            font-size: 1.5em;
        }

        .answerBox {
            text-decoration: none;
            font-size: 1.5em;
            text-align: center;
            list-style-type: none;
            word-wrap: break-word;
            width: 78vw;
            .question {
                margin-bottom: $padding/4;
            }

            .answer {
                font-size: 0.8em;
                margin-bottom: $padding/4;
                font-family: "PatrickHandSC";
            }

            .excuse {
                font-size: 0.8em;
                margin-bottom: $padding/4;
                font-family: "PatrickHandSC";
            }
        }
    }
}
</style>
